/* eslint-disable no-shadow */
const state = () => ({
  languages: [
    { value: 'de', label: 'Deutsch' },
    { value: 'fr', label: 'Französisch' },
  ],
});

const getters = {
  getLocaleName: (state) => (value) => {
    const language = state.languages.find((language) => language.value === value);
    if (language) {
      return language.label;
    }
    return '';
  },
};

export default {
  namespaced: true,
  state,
  getters,
};
