import type { App } from 'vue';
import * as Sentry from '@sentry/vue';

const SENTRY_DSN = window.env?.VITE_SENTRY_DSN || import.meta.env.VITE_SENTRY_DSN;

// eslint-disable-next-line import/prefer-default-export
export function initSentry(app: App<Element>) {
  Sentry.init({
    app,
    dsn: SENTRY_DSN,
  });
}
