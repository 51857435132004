/* eslint-disable no-shadow */
import axios from '@/services/axios';
import { setAuthToken } from '@/utils/TokenManager';

const state = () => ({
  user: null,
  loggedIn: false,
});

const getters = {
  IS_ADMIN(state) {
    return state.user?.roles?.includes('ROLE_ADMIN') || state.user?.roles?.includes('ROLE_SUPER_ADMIN');
  },
  IS_COMPANY_ADMIN(state) {
    return state.user?.roles?.includes('ROLE_ADMIN') || state.user?.roles?.includes('ROLE_SUPER_ADMIN')
      || state.user?.roles?.includes('ROLE_COMPANY_ADMIN');
  },
};

const mutations = {
  SET_USER(state, data) {
    state.user = data;
    state.loggedIn = state.user != null;
    if (state.user === null) {
      setAuthToken(null);
    }
  },
};

const actions = {
  async LOGOUT({ commit }) {
    try {
      await axios.post('/logout');
    } catch (err) {
      console.error('Could not invalidate token', err, err.message, err.response);
    }
    commit('SET_USER', null);
  },
  async LOAD_USER(ctx, { userId } = {}) {
    const id = userId ?? 'current';
    const { data: user } = await axios.get(`/users/${id}`);

    ctx.commit('SET_USER', user);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
