/* eslint-disable no-shadow */
const state = () => ({
  selectedOU: null,
});

const getters = {};

const mutations = {
  SET_SELECTED_OU(state, payload) {
    state.selectedOU = payload;
  },
};

const actions = {};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
