/* eslint-disable import/prefer-default-export */
import { createApp } from 'vue';
import { createHead } from '@vueuse/head';
import { createI18n } from 'vue-i18n';
import VueScrollTo from 'vue-scrollto';
import smoothscroll from 'smoothscroll-polyfill';
import vClickOutside from 'click-outside-vue3';
import VTooltip from 'v-tooltip';
import Toast, { POSITION } from 'vue-toastification';

import App from '@/App.vue';
import Go from '@/components/utils/Go.vue';
import Image from '@/components/utils/Image.vue';

import store from './store';
import router from './router';
import setupInterceptors from './utils/interceptors';
import checkView from './utils/vue-check-view';
import focusDirective from './directives/focus';

import de from './translations/de';
import fr from './translations/fr';

import '@vuepic/vue-datepicker/dist/main.css';
import 'vue-select/dist/vue-select.css';
import 'trix/dist/trix.css';
import 'trix/dist/trix.esm.min.js';
import 'vue-toastification/dist/index.css';
import './assets/styles/main.scss';
import { initSentry } from './services/sentry';

// polyfills
// --------------------------------
smoothscroll.polyfill();

// i18n
// --------------------------------
export const i18n = createI18n({
  legacy: false, // needed for Composition API
  globalInjection: true,
  locale: 'de',
  fallbackLocale: 'de',
  messages: { de, fr },
  silantFallbackWarn: true,
});

// app
// --------------------------------
const app = createApp(App);

// sentry
// --------------------------------
initSentry(app, router);

const head = createHead();
app.use(head);

app.use(store);
app.use(router);
app.use(i18n);
app.use(VueScrollTo);
app.use(vClickOutside);
app.use(VTooltip);
app.use(Toast, { position: POSITION.BOTTOM_RIGHT });
app.use(checkView, { insideThreshold: () => (window.innerWidth < 960 ? 0.1 : 0.15) });

window.getPath = () => window.location.pathname.replace(/\/$/, '');

// api
// --------------------------------
setupInterceptors(store, router);

// custom components
// --------------------------------
app.component('Go', Go);
app.component('Image', Image);

app.directive('focus', focusDirective);

app.compilerOptions = {
  isCustomElement: (tag) => tag !== 'trix-editor',
};

app.mount('#app');
if (!window.prerender) setTimeout(() => window.document.body.classList.remove('no-anim'), 100);
