const STORAGE_KEY = 'auth';

export function getAuthToken(): string|null {
  return localStorage.getItem(STORAGE_KEY);
}

export function setAuthToken(token: string|null = null): void {
  if (token) localStorage.setItem(STORAGE_KEY, token);
  else localStorage.removeItem(STORAGE_KEY);
}
