/* eslint-disable no-shadow */
import { format } from 'date-fns';
import axios from '@/services/axios';

const state = () => ({
  reads: [],
});

const getters = {};

const mutations = {
  setAnnouncementReads(state, reads) {
    state.reads = reads;
  },
};

const actions = {
  async fetchAnnouncementReads({ commit, rootState }) {
    if (rootState.auth.user) {
      const { data: announcementReads } = await axios.get('/announcement_reads', {
        params: {
          'exists[readAt]': false,
          'postponedUntil[before]': (new Date()).toISOString(),
          'createdAt[after]': rootState.auth.user.createdAt,
          user: rootState.auth.user.id,
        },
      });
      commit('setAnnouncementReads', announcementReads['hydra:member']);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
