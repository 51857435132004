import { createRouter, createWebHistory } from 'vue-router';
import routes from './routes';
import store from '../store';
import axios from '../services/axios';
import { i18n } from '@/main';

const router = createRouter({
  history: createWebHistory(),
  routes,
});

const isPublicOnlyRoute = (route) => [
  /^\/login/,
  /^\/register(\/[A-Za-z0-9-_]*)?/,
  /^\/reset$/,
  /^\/set-password/,
].some((r) => r.test(route.fullPath));

const isAdminRoute = (route) => [
  /^\/companies/,
  /^\/licenses/,
].some((regex) => regex.test(route.fullPath));

const canContinue = (user, to, from) => {
  if (isPublicOnlyRoute(to)) {
    return '/';
  }

  if (isAdminRoute(to) && !user.roles.includes('ROLE_SUPER_ADMIN')) {
    return from;
  }

  if (to.name !== 'onboarding' && (user.company?.dataComplete === false || user.company?.onboardingComplete === false)) {
    return { name: 'onboarding' };
  }

  return true;
};

const isPublicTaskRoute = (route) => {
  const regex = /^\/tasks\/[a-f0-9]{8}-[a-f0-9]{4}-4[a-f0-9]{3}-(8|9|a|b)[a-f0-9]{3}-[a-f0-9]{12}/;
  return regex.test(route.fullPath);
};

router.beforeEach(async (to, from) => {
  const { user } = store.state.auth;
  const loggedIn = store.state.auth.user !== null && store.state.auth.loggedIn;

  if (to.name === 'not-found') {
    return true;
  }

  if (loggedIn) {
    return canContinue(user, to, from);
  }

  try {
    const { data } = await axios.get('/users/current');

    if (data != null) {
      i18n.global.locale.value = data.locale;

      store.commit('auth/SET_USER', data);
      return canContinue(data, to, from);
    }
  } catch (err) {
    console.error('Could not fetch user', err, err.message, err.response);
  }

  if (isPublicOnlyRoute(to)) {
    return loggedIn ? '/' : true;
  }

  if (to.fullPath.startsWith('/tasks/')) {
    return true;
  }

  return '/login';
});

export default router;
