import axios from 'axios';

const instance = axios.create({
  baseURL: window.env?.VITE_API_URL || import.meta.env.VITE_API_URL,
  headers: { 'Content-Type': 'application/json' },
});

instance.interceptors.request.use((config) => {
  if (localStorage != null && localStorage.getItem('_switch_user')) {
    if (config.params == null) {
      config.params = {};
    }
    // eslint-disable-next-line no-underscore-dangle
    config.params._switch_user = localStorage.getItem('_switch_user');
  }

  return config;
}, null, { synchronous: true });

export default instance;
