/* eslint-disable no-shadow */
const state = () => ({
});

const getters = {};

const mutations = {
  SET_TREE_STATE_FOR_INTERVIEW(state, { interviewIri, treeState }) {
    state[interviewIri] = treeState;
  },
  ADD_ELEMENT_TO_TREE_STATE_FOR_INTERVIEW(state, { interviewIri, element }) {
    if (state[interviewIri].find((el) => el === element) === undefined) {
      state[interviewIri] = [...state[interviewIri], element];
    }
  },
};

const actions = {};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
