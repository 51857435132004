import { useToast } from 'vue-toastification';
import { useNetwork } from '@vueuse/core';
import { watch, type Ref } from 'vue';
import { useI18n } from 'vue-i18n';

const NETWORK_TOAST_ID = 'offline-warning';

export interface UseNeworkObserverReturn {
  isOnline: Ref<boolean>
}

/* eslint-disable import/prefer-default-export */
export function useNetworkObserver(): UseNeworkObserverReturn {
  const { t } = useI18n();
  const toast = useToast();
  const { isOnline } = useNetwork();

  watch(isOnline, (newValue) => {
    toast.dismiss(NETWORK_TOAST_ID);

    if (newValue) {
      toast.success(t('network.backOnline'), {
        id: NETWORK_TOAST_ID,
      });
    } else {
      toast.warning(t('network.offline'), {
        id: NETWORK_TOAST_ID,
        timeout: false,
        closeOnClick: false,
        draggable: false,
        closeButton: false,
      });
    }
  });

  return {
    isOnline,
  };
}
