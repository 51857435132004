/* eslint-disable no-shadow */
import axios from '@/services/axios';

const state = () => ({
  loaded: false,
  loading: false,
  countries: [],
});

const getters = {
  commonPrefixed(state) {
    return [
      { key: 'CH-1', value: 'CH', label: 'Schweiz' },
      { key: 'DE-1', value: 'DE', label: 'Deutschland' },
      { key: 'AT-1', value: 'AT', label: 'Österreich' },
      {
        key: 'divider', value: 'divider', label: '-----', disabled: true,
      },
    ].concat(state.countries);
  },
};

const mutations = {
  START_LOADING(state) {
    state.loading = true;
  },
  SET_COUNTRIES(state, payload) {
    state.countries = payload;
    if (payload?.length) {
      state.loaded = true;
    }
    state.loading = false;
  },
};

const actions = {
  async LOAD_COUNTRIES({ commit, state }) {
    if (!state.loading) {
      commit('START_LOADING');
      const { data } = await axios.get('/countries');
      const countries = [];
      Object.keys(data).forEach((code) => {
        countries.push({ key: code, value: code, label: data[code] });
      });
      commit('SET_COUNTRIES', countries);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
