<template>

  <div id="overlay" />
  <div id="tooltips" />

  <component :is="$route.meta.layout || 'div'">
    <router-view />
  </component>

  <div v-if="deployEnv === 'demo'" id="demoMode" class="state-pill danger top-level-toast">
    {{ $t('global.demoMode') }}
  </div>

  <div v-if="isImpersonating" id="impersonateToast" class="state-pill danger top-level-toast">
    {{ $t('global.impersonating', [$store.state.auth.user?.firstName, $store.state.auth.user?.lastName]) }}
    <a href="#" class="underline" @click.prevent="stopImpersonating">
      {{ $t('global.stopImpersonating') }}
    </a>
  </div>

</template>

<script>
import {
  computed, defineAsyncComponent, onMounted, provide,
} from 'vue';
import { useHead } from '@vueuse/head';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import EventEmitter from 'events';
import { useStore } from 'vuex';
import { useNetworkObserver } from './hooks/useNetworkObserver';

export default {
  components: {
    Dashboard: defineAsyncComponent(() => import('@/components/layout/Dashboard.vue')),
    Public: defineAsyncComponent(() => import('@/components/layout/Public.vue')),
    TaskLayout: defineAsyncComponent(() => import('@/components/layout/TaskLayout.vue')),
    OnboardingLayout: defineAsyncComponent(() => import('@/components/layout/OnboardingLayout.vue')),
  },
  setup() {
    useNetworkObserver();

    const store = useStore();
    const route = useRoute();
    const { locale, t } = useI18n();

    const eventEmitter = new EventEmitter();
    provide('eventEmitter', eventEmitter);

    const deployEnv = computed(() => window.env?.VITE_DEPLOY_ENV || import.meta.env.VITE_DEPLOY_ENV);
    const isImpersonating = computed(() => {
      if (localStorage != null) {
        return typeof localStorage.getItem('_switch_user') === 'string';
      }
      return false;
    });
    const stopImpersonating = async () => {
      localStorage.removeItem('_switch_user');
      const link = document.createElement('a');
      link.href = '/admin';
      link.click();
    };

    onMounted(async () => {
      if (store.state.auth.user?.locale) {
        locale.value = store.state.auth.user.locale;
      }
    });

    useHead({
      title: computed(() => `${route.meta.title ? `${t(route.meta.title)} - ` : ''}COMPLYANT`),
    });

    return {
      deployEnv,
      isImpersonating,
      stopImpersonating,
    };
  },
};
</script>

<style lang="scss" scoped>
.top-level-toast {
    @apply fixed top-4 w-96 text-center left-1/2 transform -translate-x-1/2 z-[150];
  }
</style>
