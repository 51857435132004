export default [
  {
    path: '/login',
    component: () => import('@/components/auth/LoginPage.vue'),
    meta: {
      layout: 'Public',
      title: 'title.login',
    },
    children: [
      {
        name: 'login',
        path: '',
        component: () => import('@/components/auth/LoginForm.vue'),
      },
      {
        name: 'login-2fa',
        path: '2fa',
        component: () => import('@/components/auth/Login2FAForm.vue'),
      },
      {
        name: 'login-setup-2fa',
        path: '2fa/setup',
        component: () => import('@/components/auth/LoginSetup2FAForm.vue'),
      },
    ],
  },
  {
    name: 'reset',
    path: '/reset',
    component: () => import('@/components/auth/ResetPasswordRequestPage.vue'),
    meta: {
      layout: 'Public',
      title: 'title.resetPassword',
    },
  },
  {
    name: 'set-pw',
    path: '/set-password/:token',
    component: () => import('@/components/auth/SetPasswordPage.vue'),
    meta: {
      layout: 'Public',
      title: 'title.setPassword',
    },
  },
  {
    name: 'interview-overview',
    path: '/',
    component: () => import('@/components/interview/InterviewOverviewPage.vue'),
    meta: {
      layout: 'Dashboard',
      title: 'title.interviews',
    },
  },
  {
    name: 'company-overview',
    path: '/company-overview',
    component: () => import('@/components/organisation/OrganizationOverviewPage.vue'),
    meta: {
      layout: 'Dashboard',
      title: 'title.totalOverview',
    },
  },
  {
    path: '/interview',
    component: () => import('@/components/interview/InterviewDetailPage.vue'),
    meta: {
      layout: 'Dashboard',
      title: 'title.interview',
    },
    children: [
      {
        name: 'interview-detail',
        path: '/interview/:id',
        component: () => import('@/components/interview/InterviewDetailView.vue'),
        meta: {
          layout: 'Dashboard',
          title: 'title.interview',
        },
      },
      {
        name: 'interview-questions',
        path: '/interview/:id/questions',
        component: () => import('@/components/interview/InterviewQuestionView.vue'),
        meta: {
          layout: 'Dashboard',
          title: 'title.interviewQuestions',
        },
      },
      {
        name: 'interview-questions-list',
        path: '/interview/:id/questions/list',
        component: () => import('@/components/interview/InterviewQuestionListView.vue'),
        meta: {
          layout: 'Dashboard',
          title: 'title.interviewQuestions',
        },
      },
    ],
  },
  {
    path: '/interview-template',
    component: () => import('@/components/interview/InterviewDetailPage.vue'),
    meta: {
      layout: 'Dashboard',
      title: 'title.interview',
    },
    children: [
      {
        name: 'interview-template-detail',
        path: '/interview-template/:id',
        component: () => import('@/components/interview/InterviewDetailView.vue'),
        meta: {
          layout: 'Dashboard',
          title: 'title.interview',
        },
      },
      {
        name: 'interview-template-questions',
        path: '/interview-template/:id/questions',
        component: () => import('@/components/interview/InterviewQuestionView.vue'),
        meta: {
          layout: 'Dashboard',
          title: 'title.interviewQuestions',
        },
      },
      {
        name: 'interview-template-questions-list',
        path: '/interview-template/:id/questions/list',
        component: () => import('@/components/interview/InterviewQuestionListView.vue'),
        meta: {
          layout: 'Dashboard',
          title: 'title.interviewQuestions',
        },
      },
    ],
  },
  {
    name: 'announcements',
    path: '/announcements',
    component: () => import('@/components/announcement/AnnouncementOverview.vue'),
    meta: {
      layout: 'Dashboard',
      title: 'title.announcements',
    },
  },
  {
    name: 'settings',
    path: '/settings',
    component: () => import('@/components/settings/CompanySettingsPage.vue'),
    redirect: { name: 'profile' },
    meta: {
      layout: 'Dashboard',
      title: 'title.settings',
    },
    children: [
      {
        name: 'profile',
        path: 'profile',
        component: () => import('@/components/auth/ProfilePage.vue'),
        meta: {
          layout: 'Dashboard',
          title: 'title.profile',
        },
      },
      {
        name: 'setup-google-auth',
        path: 'profile/setup-2fa',
        component: () => import('@/components/auth/SetupGoogleAuthenticator.vue'),
        meta: {
          layout: 'Dashboard',
          title: 'title.profile',
        },
      },
      {
        name: 'settings-userManagement',
        path: 'users',
        component: () => import('@/components/settings/company/UserManagementPage.vue'),
        meta: {
          layout: 'Dashboard',
          title: 'title.settings',
        },
      },
      {
        name: 'settings-billing',
        path: 'billing',
        component: () => import('@/components/settings/company/CompanyBillingPage.vue'),
        meta: {
          layout: 'Dashboard',
          title: 'title.settings',
        },
      },
      {
        name: 'settings-companyData',
        path: 'data',
        component: () => import('@/components/settings/company/CompanyDataPage.vue'),
        meta: {
          layout: 'Dashboard',
          title: 'title.settings',
        },
      },
      {
        name: 'settings-toggles',
        path: 'data',
        component: () => import('@/components/settings/company/CompanyTogglesPage.vue'),
        meta: {
          layout: 'Dashboard',
          title: 'title.settings',
        },
      },
      {
        name: 'settings-organizationalUnits',
        path: 'organizational-units',
        component: () => import('@/components/settings/company/OrganizationalUnitsPage.vue'),
        meta: {
          layout: 'Dashboard',
          title: 'title.settings',
        },
      },
      {
        name: 'settings-prototypes',
        path: 'prototype',
        component: () => import('@/components/settings/company/PrototypesPage.vue'),
        meta: {
          layout: 'Dashboard',
          title: 'title.settings',
        },
      },
      {
        name: 'settings-theme',
        path: 'theme',
        component: () => import('@/components/settings/company/ThemePage.vue'),
        meta: {
          layout: 'Dashboard',
          title: 'title.settings',
        },
      },
      {
        name: 'settings-import',
        path: 'import',
        component: () => import('@/components/settings/company/ImportPage.vue'),
        meta: {
          layout: 'Dashboard',
          title: 'title.settings',
        },
      },
    ],
  },
  {
    name: 'tasks',
    path: '/tasks',
    component: () => import('@/components/task/TaskPage.vue'),
    meta: {
      layout: 'Dashboard',
      title: 'title.tasks',
    },
  },
  {
    name: 'public-tasks',
    path: '/tasks/:id',
    component: () => import('@/components/task/PublicTaskPage.vue'),
    meta: {
      layout: 'TaskLayout',
      title: 'title.tasks',
    },
  },
  {
    name: 'admin-dashboard',
    path: '/admin',
    component: () => import('@/components/admin/AdminDashboard.vue'),
    meta: {
      layout: 'Dashboard',
      title: 'title.adminDashboard',
    },
  },
  {
    name: 'admin-files',
    path: '/admin/files',
    component: () => import('@/components/admin/AdminHelpFiles.vue'),
    meta: {
      layout: 'Dashboard',
      title: 'title.adminDashboard',
    },
  },
  {
    name: 'admin-topic-overview',
    path: '/admin/topics',
    component: () => import('@components/admin/AdminTopicsOverviewPage.vue'),
    meta: {
      layout: 'Dashboard',
      title: 'title.topicOverview',
    },
  },
  {
    name: 'admin-topic',
    path: '/admin/topic',
    component: () => import('@components/admin/TopicDetailPage.vue'),
    meta: {
      layout: 'Dashboard',
      title: 'title.topic',
    },
    children: [
      {
        name: 'admin-topic-detail',
        path: '/admin/topic/:id',
        component: () => import('@/components/admin/TopicDetailView.vue'),
        meta: {
          layout: 'Dashboard',
          title: 'title.topic',
        },
      },
      {
        name: 'admin-template-edit',
        path: '/admin/topic/:id/template/:templateId',
        component: () => import('@/components/admin/TemplateEditView.vue'),
        meta: {
          layout: 'Dashboard',
          title: 'title.template',
        },
      },
    ],
  },
  {
    name: 'admin-links',
    path: '/admin/links',
    component: () => import('@/components/link/LinkPage.vue'),
    meta: {
      layout: 'Dashboard',
      title: 'title.links',
    },
  },
  {
    name: 'admin-companies',
    path: '/admin/companies',
    component: () => import('@/components/company/CompanyManagementPage.vue'),
    meta: {
      layout: 'Dashboard',
      title: 'title.customers',
    },
  },
  {
    name: 'admin-companies-create',
    path: '/admin/companies/create',
    component: () => import('@/components/company/CreateCompany.vue'),
    meta: {
      layout: 'Dashboard',
      title: 'title.customers',
    },
  },
  {
    name: 'admin-company',
    path: '/admin/companies/:id',
    component: () => import('@/components/company/CompanyPage.vue'),
    meta: {
      layout: 'Dashboard',
      title: 'title.customers',
    },
    children: [
      {
        name: 'admin-company-general',
        path: '/admin/companies/:id/general',
        component: () => import('@components/company/GeneralCompanySettings.vue'),
        meta: {
          layout: 'Dashboard',
          title: 'title.customers',
        },
      },
      {
        name: 'admin-company-users',
        path: '/admin/companies/:id/users',
        component: () => import('@components/company/UserManagement.vue'),
        meta: {
          layout: 'Dashboard',
          title: 'title.customers',
        },
      },
    ],
  },
  {
    name: 'license',
    path: '/admin/licenses',
    component: () => import('@/components/license/LicensesPage.vue'),
    meta: {
      layout: 'Dashboard',
      title: 'title.licenses',
    },
  },
  {
    name: 'onboarding',
    path: '/onboarding',
    component: () => import('@/components/settings/OnboardingPage.vue'),
    meta: {
      layout: 'OnboardingLayout',
      title: 'title.onboarding',
    },
  },
  {
    name: 'admin-announcements',
    path: '/admin/announcements',
    component: () => import('@/components/admin/AdminAnnouncementOverview.vue'),
    meta: {
      layout: 'Dashboard',
      title: 'title.announcements',
    },
  },
  {
    name: 'not-found',
    path: '/:pathMatch(.*)*',
    component: () => import('@/components/layout/TaskNotFound.vue'),
    meta: {
      layout: 'TaskLayout',
      title: 'title.tasks',
    },
  },
];
