/* eslint-disable no-param-reassign */
import { getAuthToken } from './TokenManager';
import axios from '@/services/axios';

const setup = (store, router) => {
  axios.interceptors.request.use(
    (config) => {
      const authData = getAuthToken();
      if (authData) {
        config.headers.Authorization = `Bearer ${authData}`;
      }
      return config;
    },
    (error) => Promise.reject(error),
  );

  // Logout in case API response is status 401
  axios.interceptors.response.use(
    (res) => res,
    async (err) => {
      if (err.response?.status === 401 && err.config.url !== '/logout') {
        await store.commit('auth/SET_USER', null);
        await router.push('/login');
      }

      return Promise.reject(err);
    },
  );
};

export default setup;
