import { createStore, createLogger } from 'vuex';
import general from './modules/general';
import auth from './modules/auth';
import announcements from './modules/announcements';
import organization from './modules/organization';
import questionTree from './modules/questionTree';
import interviews from './modules/interviews';
import countries from './modules/country';

const debug = process.env.NODE_ENV !== 'production';

export default createStore({
  modules: {
    general,
    auth,
    announcements,
    countries,
    organization,
    questionTree,
    interviews,
  },
  strict: debug,
  plugins: debug ? [createLogger()] : [],
});
